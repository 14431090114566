<template>
    <div id="episodes" class="page-wrapper page-episodes">
        <!-- Grid Thumbs -->
        <section class="grid-thumbs site-max-width light" hero-transition-group>
            <div class="inner grid-thumbs-content">
                <h1 class="grid-thumbs-title title" data-inview="fadeInUp" data-delay="100">
                    <span v-html="contentIsLoaded ? content.intro.titre : '&nbsp;'"></span>
                </h1>
                <p class="grid-thumbs-text text" data-inview="fadeInUp" data-delay="200">
                    <span v-html="contentIsLoaded ? content.intro.texte : '&nbsp;'"></span>
                </p>
                <nav id="episodes-nav" class="episodes-nav" data-inview="fadeInUp" data-delay="300">
                    <div class="nav-links" v-if="pageIsLoaded">
                        <a href="#" class="nav-link" :class="{ 'is-active': filter === '' }" :title="$t('Tous les épisodes')" @click.prevent="filterBy('')"
                            ><span>{{ $t('Tous les épisodes') }}</span></a
                        >
                        <a href="#" class="nav-link" :class="{ 'is-active': filter === item.slug }" title="Thème 1" v-for="(item, i) in globals.categoriesDepisodes.data" :key="i" @click.prevent="filterBy(item.slug)"><span>{{ item.title }}</span></a>
                    </div>
                </nav>
                <div class="grid-thumbs-items" data-inview="fadeInUp" data-delay="400">
                    <router-link
                        :to="item.page.jsonUrl"
                        class="grid-thumbs-item"
                        v-for="(item, i) in episodesList" :key="i"
                    >
                        <img :src="item.page.imageSimple.url" :alt="item.page.imageSimple.titre" />
                        <div class="content">
                            <h3 class="item-title">{{ item.page.titre }}</h3>
                            <span href="#" class="site-btn" :title="$t('Voir plus')">{{ $t('Voir plus') }}</span>
                        </div>
                    </router-link>

                    <p data-inview="fadeInUp" data-delay="200" class="grid-thumbs-text text is-in-view is-empty" v-if="pageIsLoaded && !episodesList.length"><span>{{ $t('Aucun épisode pour cette catégorie.') }}</span></p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'Episodes',

    data() {
        return {
            filter: '',
        }
    },

    components: {
        // HelloWorld,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                }
            },
            immediate: true,
        },
    },

    methods: {
        filterBy(filter) {
            this.filter = filter
        }
    },

    computed: {
        episodesList() {
            if (!this.pageIsLoaded) {
                return []
            }

            if (this.filter) {
                return this.globals.episodesEntry.data.filter(item => item.page.categorieDepisode.includes(this.filter))
            }

            return this.globals.episodesEntry.data
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
